<template>
  <div class="searchView">
    <van-nav-bar title="搜索" left-arrow @click-left="onClickLeft" />
    <form action="/">
      <van-search
        v-model="keywords"
        show-action
        placeholder="请输入搜索关键词"
        shape="round"
        @search="onSearch"
      >
        <van-button
          slot="action"
          native-type="button"
          round
          type="danger"
          size="mini"
          @click="onSearch"
        >搜索</van-button>
      </van-search>
    </form>
    <div class="near-le-ri p">
      <div class="maleri30">
        <span>热门搜索</span>
      </div>
    </div>
    <div class="lb_showhide se_shien p" style="display: block;">
      <div class="maleri30">
        <ul>
          <li>
            <a href="/mobile/Goods/search/q/%E6%89%8B%E6%9C%BA.html" class="ht">手机</a>
          </li>
          <li>
            <a href="/mobile/Goods/search/q/%E5%8D%8E%E4%B8%BA.html">华为</a>
          </li>
          <li>
            <a href="/mobile/Goods/search/q/%E8%A1%A3%E6%9C%8D.html">衣服</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      keywords: ""
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go("-1");
    },
    onSearch() {}
  }
};
</script>
<style lang="stylus">
.searchView
  background-color: #ffffff
  .van-search__action
    background-color: white
    .van-button--mini
      height: 0.78667rem
  .maleri30
    margin: 0 0.427rem
    font-size: 0.38rem
    font-weight: 500
  .near-le-ri
    padding: 0.35333rem 0 0.42667rem 0
    .near-le-ri span
      float: left
      font-size: 0.64rem
  .lb_showhide
    display: none
    position: fixed
    z-index: 999
    background-color: #fff
    overflow: hidden
    width: 13.44rem
    height: 13.44rem
    margin: 0 auto
    margin-left: 1.28rem
    overflow: auto
    -moz-border-radius: 0.21333rem
    -webkit-border-radius: 0.21333rem
    border-radius: 0.21333rem
    ul
      li
        float: left
        margin-right: 0.42rem
        height: 1rem
        line-height: 1rem
        text-align: center
        a
          background-color: #f5f5f5
          border-radius: 0.53rem
          border: none
          font-size: 0.36rem
          padding: 0.15rem 0.44rem
          color: #666
  .se_shien
    position: inherit
    width: 100%
    height: inherit
    margin-left: 0
</style>